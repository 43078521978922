import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import Message from "../components/Message";
import { addToCart, removeFromCart } from "../slices/cartSlice";
import "../assets/styles/index.css";

const CartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  // Updated CartScreen to store sizeType
  const addToCartHandler = async (productItem, quantity) => {
    const sizeObj = productItem.sizes.find(
      (size) => size.size === productItem.size
    );

    if (sizeObj) {
      const updatedItem = {
        ...productItem,
        quantity,
        sizeType: productItem.sizeType, // Store the selected size type if applicable
        price: sizeObj.price, // Correctly set price based on selected size
        countInStock: sizeObj.countInStock, // Ensure correct stock count is used
      };
      dispatch(addToCart(updatedItem));
    } else {
      console.error(
        `Size ${productItem.size} not found for product ${productItem.name}`
      );
    }
  };

  const removeFromCartHandler = async (id, size) => {
    dispatch(removeFromCart({ _id: id, size }));
  };

  const checkoutHandler = () => {
    navigate("/shipping");
  };

  const calculateSubtotal = () => {
    return cartItems
      .reduce((acc, item) => acc + item.quantity * item.price, 0)
      .toFixed(2);
  };

  // Helper function to display size in the selected format if category is "Shoes"
  const getSizeDisplay = (item) => {
    if (item.category === "Shoes") {
      const sizeMapping = item.sizeMapping?.EU[item.size] || {};
      if (item.sizeType === "EU") {
        return `EU-${item.size}`;
      } else if (item.sizeType === "US") {
        return `US-${sizeMapping.US}`;
      } else if (item.sizeType === "UK") {
        return `UK-${sizeMapping.UK}`;
      } else {
        return `EU-${item.size}`; // Default to EU if sizeType is not set
      }
    } else {
      return `Size: ${item.size}`; // For non-shoes categories, just show the size
    }
  };

  // The updated "Go Back" button functionality
  const goBackHandler = () => {
    navigate(-1); // Navigates back to the previous page in history
  };

  return (
    <Row>
      <Col md={8}>
        <h1 className="mb-4 text-custom-orange text-3xl font-medium">
          Shopping Cart
        </h1>
        {cartItems.length === 0 ? (
          <p className="bg-orange-400 h-14 flex items-center text-white rounded-md pl-4 font-medium">
            Your Cart is Empty!{" "}
            <span onClick={goBackHandler} className="ml-2">
              <button className="px-2 rounded-md bg-black mt-[4px] hover:cursor-pointer hover:scale-105 transition-all">
                Go Back
              </button>
            </span>
          </p>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((item) => {
              const sizeObj = item.sizes.find(
                (size) => size.size === item.size
              );
              return (
                <ListGroup.Item
                  key={`${item._id}-${item.size}`}
                  className="text-black font-medium"
                >
                  <Row>
                    <Col md={2}>
                      <Image
                        src={item.images[1]}
                        alt={item.name}
                        fluid
                        rounded
                      />
                    </Col>
                    <Col md={3}>
                      <Link to={`/product/${item._id}`}>
                        {item.name} <br />
                        <small>{getSizeDisplay(item)}</small>
                      </Link>
                    </Col>
                    <Col md={2}>
                      {sizeObj ? `${sizeObj.price} PKR` : "Price Not Available"}
                    </Col>
                    <Col md={2}>
                      <Form.Control
                        as="select"
                        value={item.quantity}
                        onChange={(e) =>
                          addToCartHandler(item, Number(e.target.value))
                        }
                      >
                        {sizeObj ? (
                          [...Array(sizeObj.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))
                        ) : (
                          <option value="0">N/A</option>
                        )}
                      </Form.Control>
                    </Col>
                    <Col md={2}>
                      <Button
                        type="button"
                        onClick={() =>
                          removeFromCartHandler(item._id, item.size)
                        }
                        className="p-[14px] rounded bg-custom-orange hover:bg-orange-600 hover:scale-105 transition-all"
                      >
                        <FaTrash className="text-white" />
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item className="text-black text-lg">
              <h2>
                Subtotal (
                {cartItems.reduce((acc, curr) => acc + curr.quantity, 0)}) Items
              </h2>
              PKR {calculateSubtotal()}
            </ListGroup.Item>

            <ListGroup.Item>
              <Link
                className="animated-btn"
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                Proceed to Checkout
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default CartScreen;
