import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import { useGetAllCategoriesQuery, useGetAllBrandsForCategoryQuery, useGetSizeMappingsQuery } from "../slices/mappingApiSlice";
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import "../assets/styles/index.css";

const PublicProductListScreen = () => {
  const { keyword, pageNumber = 1 } = useParams(); // Default to page 1 if no page number is provided
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(""); // Track selected category
  const [available, setAvailable] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);

  const filterRef = useRef();

  // Fetch products based on filters, or all products if no filter is selected
  const { data, error, isLoading } = useGetProductsQuery({
    keyword,
    pageNumber,
    sizes: selectedSizes.join(","), // Add sizes as filter
    brand: selectedBrands.join(","), // Add brand as filter
    category: selectedCategory || "", // Ensure category is sent to the API
    available,
  });

  // Fetch categories, brands, and size mappings
  const { data: categories } = useGetAllCategoriesQuery(); // Fetch all categories
  const { data: brands } = useGetAllBrandsForCategoryQuery(selectedCategory, { skip: !selectedCategory });
  const { data: sizeMappings } = useGetSizeMappingsQuery(selectedCategory, { skip: !selectedCategory });

  useEffect(() => {
    setLoading(true);
  }, [selectedSizes, selectedBrands, available, selectedCategory]);

  useEffect(() => {
    if (!isLoading && data) {
      setLoading(false);
    }
  }, [data, isLoading]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSizeChange = (size) => {
    setSelectedSizes((prev) =>
      prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
    );
  };

  const handleBrandChange = (brand) => {
    setSelectedBrands((prev) =>
      prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
    );
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedBrands([]); // Reset brand and size filters when category changes
    setSelectedSizes([]);
  };

  const clearFilters = () => {
    setSelectedSizes([]);
    setSelectedBrands([]);
    setAvailable(false);
    setSelectedCategory(""); // Clear category selection
  };

  // Function to display the correct sizes based on the category
  const getSizesForCategory = () => {
    if (selectedCategory === "Shoes") {
      return ["40", "40.5", "41", "41.5", "42", "42.5", "43", "43.5", "44", "44.5", "45", "45.5", "46", "46.5", "47"];
    } else if (selectedCategory === "Shirts") {
      return ["XS", "S", "M", "L", "XL", "XXL"];
    } else if (selectedCategory === "Pants") {
      return ["32", "34", "36", "38", "40", "42", "44", "46"];
    }
    return [];
  };

  // Close filters when clicking outside of the panel on mobile devices only
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth < 640 &&
        filterRef.current &&
        !filterRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    };

    if (showFilters) {
      document.addEventListener("mousedown", handleClickOutside);
      // Disable scrolling on mobile devices when the filter panel is open
      if (window.innerWidth < 640) {
        document.body.classList.add("overflow-hidden");
      }
    } else {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilters]);

  return (
    <>
      <div className="flex justify-between items-center my-10">
        <h1 className="font-extrabold text-3xl text-center inline-block text-black">
          Explore All Products
        </h1>
        <button
          className="animated-btn"
          onClick={toggleFilters}
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
          <i className="fas fa-sliders-h"></i>
        </button>
      </div>

      {/* Black Overlay for mobile */}
      {showFilters && (
        <div
          className="fixed inset-0 bg-black opacity-50 sm:hidden z-40"
          onClick={toggleFilters} // Close the filters when clicking outside
        ></div>
      )}

      <div className="flex transition-all duration-500 sm:pr-10 pr-3">
        {/* Filters Panel */}
        <div
          ref={filterRef}
          className={`transition-all duration-500 ${
            showFilters ? "sm:w-1/4 w-3/4 sm:opacity-100" : "w-0 opacity-0"
          } sm:p-4 p-8 bg-white sm:h-auto h-[75vh] sm:relative fixed sm:left-0 left-0 top-0 z-50 overflow-hidden border-r-2`}
          style={{
            minHeight: "100vh",
            transition: "all 0.5s ease",
          }}
        >
          {/* Close button for mobile */}
          <button
            className="sm:hidden absolute top-4 right-4 text-2xl z-50"
            onClick={toggleFilters}
          >
            &times;
          </button>

          {/* Filter by Category */}
          <div className="mb-4">
            <h4 className="font-semibold mb-2">Category</h4>
            <div className="flex flex-wrap gap-2">
              {categories &&
                categories.map((category) => (
                  <button
                    key={category}
                    className={`px-3 py-1 rounded border ${
                      selectedCategory === category
                        ? "bg-blue-500 text-white"
                        : "bg-white text-black"
                    }`}
                    onClick={() => handleCategoryChange(category)}
                  >
                    {category}
                  </button>
                ))}
            </div>
          </div>

          {/* Filter by Size */}
          <div className="mb-4">
            <h4 className="font-semibold mb-2">Size</h4>
            <div className="flex flex-wrap gap-2">
              {getSizesForCategory().map((size) => (
                <button
                  key={size}
                  className={`px-3 py-1 rounded border ${
                    selectedSizes.includes(size)
                      ? "bg-blue-500 text-white"
                      : "bg-white text-black"
                  }`}
                  onClick={() => handleSizeChange(size)}
                >
                  {size}
                </button>
              ))}
            </div>
          </div>

          {/* Filter by Brand */}
          <div className="mb-4">
            <h4 className="font-semibold mb-2">Brand</h4>
            <div className="flex flex-wrap gap-2">
              {brands &&
                brands.map((brand) => (
                  <button
                    key={brand}
                    className={`px-3 py-1 rounded border ${
                      selectedBrands.includes(brand)
                        ? "bg-blue-500 text-white"
                        : "bg-white text-black"
                    }`}
                    onClick={() => handleBrandChange(brand)}
                  >
                    {brand}
                  </button>
                ))}
            </div>
          </div>

          {/* Filter by Availability */}
          <div className="mb-4">
            <h4 className="font-semibold mb-2">Availability</h4>
            <label className="flex items-center">
              <input
                type="checkbox"
                value="available"
                onChange={() => setAvailable(!available)}
                checked={available}
                className="mr-2"
              />
              <span>In Stock</span>
            </label>
          </div>

          {/* Clear Filters Button */}
          <button
            onClick={clearFilters}
            className="bg-red-500 animated-btn"
          >
            Clear All Filters
          </button>
        </div>

        {/* Products List */}
        <div
          className={`transition-all duration-500 ${
            showFilters ? "sm:w-3/4 w-full" : "w-full"
          } ml-4`}
        >
          {isLoading || loading ? (
            <Loader />
          ) : error ? (
            <Message>{error?.data?.message || error.error}</Message>
          ) : (
            <>
              <div className="product-grid">
                {data.fetchedProducts.map((product) => (
                  <Product key={product._id} product={product} />
                ))}
              </div>
              <Paginate
                pages={data.pages}
                page={data.page}
                keyword={keyword ? keyword : ""}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PublicProductListScreen;
