import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { toast } from "react-toastify";
import {
  useGetSingleProductQuery,
  useEditProductMutation,
  useUploadProductImageMutation,
} from "../../slices/productsApiSlice";
import {
  useGetSizeMappingsQuery,
  useGetAllCategoriesQuery,
  useGetAllBrandsForCategoryQuery,
} from "../../slices/mappingApiSlice";

// Helper function to sort sizes numerically, accounting for floating-point numbers
const sortSizes = (sizes) => {
  return sizes.sort((a, b) => parseFloat(a.size) - parseFloat(b.size));
};

// Helper function to merge existing size data with the default structure from sizeMappings
const mergeSizesWithMappings = (existingSizes = [], sizeMappings) => {
  return sizeMappings.map((mappingSize) => {
    const existingSize = existingSizes.find((size) => size.size === mappingSize.size);
    return existingSize ? { ...mappingSize, ...existingSize } : mappingSize;
  });
};

const ProductEditScreen = () => {
  const { id: productId } = useParams();
  const [name, setName] = useState("");
  const [images, setImages] = useState([]);
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [sizes, setSizes] = useState([]);

  const {
    data: singleProduct,
    isLoading: singleProductLoading,
    error: singleProductError,
    refetch,
  } = useGetSingleProductQuery(productId);

  const { data: categories, isLoading: categoriesLoading, error: categoriesError } = useGetAllCategoriesQuery();

  const {
    data: brands,
    isLoading: brandsLoading,
    error: brandsError,
  } = useGetAllBrandsForCategoryQuery(category, { skip: !category });

  const { data: sizeMappings, error: sizeMappingsError } = useGetSizeMappingsQuery(
    { category, brand },
    { skip: !category || !brand }
  );

  const [editProduct, { isLoading: loadingEdit }] = useEditProductMutation();
  const [uploadProductImage, { isLoading: loadingUpload }] =
    useUploadProductImageMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (singleProduct) {
      setName(singleProduct.name);
      setImages(singleProduct.images);
      setBrand(singleProduct.brand);
      setCategory(singleProduct.category);
      setDescription(singleProduct.description);
      setSizes(singleProduct.sizes || []);
    }
  }, [singleProduct]);

  // Populate sizes based on the category and brand and retain current product values
  useEffect(() => {
    if (sizeMappings) {
      let defaultSizes = [];

      // Check category and extract the sizes accordingly
      if (category === "Shoes" && sizeMappings["EU"]) {
        defaultSizes = Object.keys(sizeMappings["EU"]).map((size) => ({
          size,
          available: false,
          countInStock: 0,
          price: 0,
          cost: 0,
        }));
      } else if (["Shirts", "Pants"].includes(category) && sizeMappings["Sizes"]) {
        defaultSizes = sizeMappings["Sizes"].map((size) => ({
          size,
          available: false,
          countInStock: 0,
          price: 0,
          cost: 0,
        }));
      }

      // Use existing sizes from `singleProduct` if available
      const mergedSizes = mergeSizesWithMappings(singleProduct?.sizes || [], defaultSizes);
      setSizes(sortSizes(mergedSizes));
    }
  }, [sizeMappings, category, singleProduct?.sizes]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setBrand(""); // Reset brand when category changes
    setSizes([]); // Reset sizes when category changes
  };

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
    setSizes([]); // Reset sizes when brand changes
  };

  const handleSizeChange = (index, field, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[index] = { ...updatedSizes[index], [field]: value };
    setSizes(updatedSizes);
  };

  const productEditSubmitHandler = async (e) => {
    e.preventDefault();
    const newProduct = {
      productId,
      name,
      images,
      brand,
      category,
      description,
      sizes,
    };

    try {
      await editProduct(newProduct).unwrap();
      toast.success("Product Updated Successfully");
      navigate("/admin/productlist");
    } catch (error) {
      toast.error(error?.data?.message || error.error);
      console.error("Edit Product Error:", error);
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    try {
      const res = await uploadProductImage(formData).unwrap();
      toast.success(res.message);
      setImages([...images, ...res.images]);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const renderError = (error) => {
    return (
      <Message variant="danger">
        {error?.data?.message || error.message || "An error occurred"}
      </Message>
    );
  };

  return (
    <>
      <Link to="/admin/productlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingEdit && <Loader />}

        {singleProductLoading ? (
          <Loader />
        ) : singleProductError ? (
          renderError(singleProductError)
        ) : categoriesError ? (
          renderError(categoriesError)
        ) : (
          <Form onSubmit={productEditSubmitHandler}>
            <Form.Group controlId="name" className="my-2">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="category" className="my-2">
              <Form.Label>Product Category</Form.Label>
              {categoriesLoading ? (
                <Loader />
              ) : (
                <Form.Control
                  as="select"
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select Category</option>
                  {categories &&
                    categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                </Form.Control>
              )}
            </Form.Group>

            {category && (
              <Form.Group controlId="brand" className="my-2">
                <Form.Label>Product Brand</Form.Label>
                {brandsLoading ? (
                  <Loader />
                ) : (
                  <Form.Control
                    as="select"
                    value={brand}
                    onChange={handleBrandChange}
                    disabled={!category}
                  >
                    <option value="">Select Brand</option>
                    {brands && brands.length > 0 ? (
                      brands.map((brand) => (
                        <option key={brand} value={brand}>
                          {brand}
                        </option>
                      ))
                    ) : (
                      <option value="">No brands available</option>
                    )}
                  </Form.Control>
                )}
              </Form.Group>
            )}

            <Form.Group controlId="images" className="my-2">
              <Form.Label>Product Images</Form.Label>
              <Form.Control
                type="file"
                label="Choose Files"
                multiple
                onChange={uploadFileHandler}
              ></Form.Control>
              <div>
                {images.map((img, index) => (
                  <img key={index} src={img} alt={`Product ${index}`} width="50" />
                ))}
              </div>
            </Form.Group>

            {loadingUpload && <Loader />}

            {sizes && sizes.length > 0 && (
              <Form.Group controlId="sizes" className="my-2">
                <Form.Label>Product Sizes</Form.Label>
                <Row>
                  <Col><strong>Size</strong></Col>
                  <Col><strong>Stock Count</strong></Col>
                  <Col><strong>Selling Price</strong></Col>
                  <Col><strong>Cost Price</strong></Col>
                  <Col><strong>Available</strong></Col>
                </Row>
                {sizes.map((size, index) => (
                  <Row key={index} className="mb-2">
                    <Col>
                      <Form.Control type="text" value={size.size} readOnly />
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        value={size.countInStock}
                        onChange={(e) =>
                          handleSizeChange(index, "countInStock", e.target.value)
                        }
                        placeholder="Stock Count"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        value={size.price}
                        onChange={(e) =>
                          handleSizeChange(index, "price", e.target.value)
                        }
                        placeholder="Selling Price"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        value={size.cost}
                        onChange={(e) =>
                          handleSizeChange(index, "cost", e.target.value)
                        }
                        placeholder="Cost Price"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Available"
                        checked={size.available}
                        onChange={(e) =>
                          handleSizeChange(index, "available", e.target.checked)
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </Form.Group>
            )}

            <Form.Group controlId="description" className="my-2">
              <Form.Label>Product Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="my-2">
              Edit Product
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
