import { Link } from "react-router-dom";
import Rating from "./Rating";
import React from "react";
import "../assets/styles/product-card.css"; // Import the new product card styles

function Product({ product }) {
  const getProductPriceRange = () => {
    const availableInStockSizes = product.sizes.filter(size => size.available && size.countInStock > 0);

    if (availableInStockSizes.length === 0) {
      return 'Unavailable'; 
    }

    const prices = availableInStockSizes.map(size => size.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    // return minPrice === maxPrice ? `${minPrice} PKR` : `${minPrice} PKR - ${maxPrice} PKR`;
    return minPrice === maxPrice ? `${minPrice} PKR` : `${minPrice} PKR`;
  };

  return (
    <Link
      to={`/product/${product._id}`}
      className="product-card hover:cursor-pointer transition-all"
    >
      <div className="product-image-container">
        <img
          src={product.images[1] || product.images[0]} 
          alt={product.name || "Not available!!!"}
          className="product-image"
        />
      </div>
      <div className="product-info text-black pt-2">
        <span className="text-xs text-custom-orange font-semibold">Just In</span>
        <h4 className="mt-1 font-semibold text-base hover:text-custom-orange pt-1">{product.name}</h4>
        <p className="text-sm text-gray-600 pt-1">Sports Shoes</p>
        <Rating ratingValue={product.rating} ratingPosition="justify-start" />
        <span className="text-black font-bold mt-2 block price">{getProductPriceRange()}</span>
      </div>
    </Link>
  );
}

export default Product;
